import React from 'react'
import { Box, Container, Group } from '@mantine/core'
import { GlobalAnnouncement } from '../GlobalAnnouncement'
import { TopBanner } from './TopBanner'
import { NavigationMobileMenu, NavigationMobileMenuProvider } from './NavigationMobileMenu'
import { NavigationWalletDropdown } from './NavigationWalletDropdown'
import { SidebarLite } from './SidebarLite'
import { FEATURE_FLAGS } from '@/feature-flags'

interface GlobalLayoutProps {
  children: React.ReactNode
}

const GlobalLayout: React.FC<GlobalLayoutProps> = ({ children }) => {
  return (
    <>
      <GlobalAnnouncement />

      <Group spacing={0} align="flex-start" noWrap>
        {FEATURE_FLAGS.DYDX_TESTNET ? null : <SidebarLite />}

        <Box sx={{ width: '100%' }}>
          <TopBanner />

          <Container px={16} fluid>
            <Group align="center" position="apart" py={16} spacing={0}>
              <Box />

              <Group spacing="sm">
                <NavigationWalletDropdown />

                {FEATURE_FLAGS.DYDX_TESTNET ? null : (
                  <NavigationMobileMenuProvider>
                    <NavigationMobileMenu />
                  </NavigationMobileMenuProvider>
                )}
              </Group>
            </Group>
          </Container>

          <Box pb="xl">{children}</Box>
        </Box>
      </Group>
    </>
  )
}

export { GlobalLayout }
